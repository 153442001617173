import { createStyles, Theme } from '@nelnet/unifi-components-react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const backgroundColor = ({ palette }: Theme) =>
  createStyles({
    green: {
      backgroundColor: palette.primary.main,
    },
    blue: {
      backgroundColor: palette.secondary.main,
    },
    lightBlue: {
      backgroundColor: palette.secondary.light,
    },
    magenta: {
      backgroundColor: palette.extended.magenta,
    },
    purple: {
      backgroundColor: palette.extended.purple,
    },
    darkGray: {
      backgroundColor: palette.grey[500],
    },
    lightGray: {
      backgroundColor: palette.grey[200],
    },
  });

export default backgroundColor;
