import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import LayoutFrame from '../components/layout/LayoutFrame';
import KitchenSink from '../components/layout/KitchenSink';

const KitchenSinkPage: React.FunctionComponent = () => {
  return (
    <LayoutFrame currPath="/kitchen-sink/">
      <Helmet>
        <title>Kitchen Sink</title>
      </Helmet>
      <KitchenSink></KitchenSink>
    </LayoutFrame>
  );
};

export default KitchenSinkPage;
