import * as React from 'react';

import { navigate } from 'gatsby';

import { ILinkProps, Link as UnifiLink } from '@nelnet/unifi-components-react';

const Link: React.FunctionComponent<ILinkProps> = ({ children, href, ...other }) => {
  // This assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(href);
  // const anchor = /^\#(?!\/)/.test(href);

  // Use Gatsby navigate for internal links
  if (internal) {
    return (
      <UnifiLink
        href={href}
        onClick={(event: React.MouseEvent) => {
          event.preventDefault();
          navigate(href);
        }}
        {...other}
      >
        {children}
      </UnifiLink>
    );
  }

  return (
    <UnifiLink href={href} target="_blank" {...other}>
      {children}
    </UnifiLink>
  );
};

export default Link;
