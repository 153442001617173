import * as React from 'react';

import {
  Button,
  Divider,
  Grid,
  Notification,
  Paper,
  Typography,
  WithStyles,
  withStyles,
} from '@nelnet/unifi-components-react';

import ReportProblem from '@material-ui/icons/ReportProblem';
import Info from '@material-ui/icons/Info';
import Link from '../Link';

import styles from '../../../components/layout/styles/backgrounds';

interface IKitchenSink extends WithStyles<typeof styles> {
  currPath?: string;
}

const KitchenSink: React.FunctionComponent<IKitchenSink> = ({ classes }) => {
  return (
    <Grid>
      <Grid container justify="center">
        <Paper padding={3} margin={2} style={{ width: '90ch' }}>
          <Typography variant="h1" gutterBottom>
            Typography Scale
          </Typography>
          <Divider margin={{ bottom: 2 }} />
          <Grid container constrained margin={{ bottom: 3 }}>
            <Grid item xs={8}>
              <Typography variant="h1" gutterBottom>
                H1 / Red Hat Display Bold
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" gutterBottom>
                font-size: 45px (2.8125rem)
              </Typography>
              <Typography variant="body1" gutterBottom>
                line-height: 16px (1)
              </Typography>
              <Typography variant="body1" gutterBottom>
                letter-spacing: 2px
              </Typography>
              <Typography variant="body1" gutterBottom>
                text-align: center
              </Typography>
              <Typography variant="body1" gutterBottom>
                text-transform: uppercase
              </Typography>
            </Grid>
          </Grid>
          <Divider margin={{ bottom: 2 }} />
          <Grid container constrained margin={{ bottom: 3 }}>
            <Grid item xs={8}>
              <Typography variant="h2" gutterBottom>
                H2 / Red Hat Display Bold
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" gutterBottom>
                font-size: 18px (1.125rem)
              </Typography>
              <Typography variant="body1" gutterBottom>
                line-height: 20px (1.25)
              </Typography>
              <Typography variant="body1" gutterBottom>
                letter-spacing: 2.5px
              </Typography>
              <Typography variant="body1" gutterBottom>
                text-align: center
              </Typography>
              <Typography variant="body1" gutterBottom>
                text-transform: uppercase
              </Typography>
            </Grid>
          </Grid>
          <Divider margin={{ bottom: 2 }} />
          <Grid container constrained margin={{ bottom: 3 }}>
            <Grid item xs={8}>
              <Typography variant="h3" gutterBottom>
                H3 / Red Hat Display Regular
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" gutterBottom>
                font-size: 12px (0.75rem)
              </Typography>
              <Typography variant="body1" gutterBottom>
                line-height: 15px (0.9375)
              </Typography>
              <Typography variant="body1" gutterBottom>
                letter-spacing: 1.5px
              </Typography>
              <Typography variant="body1" gutterBottom>
                text-align: center
              </Typography>
            </Grid>
          </Grid>
          <Divider margin={{ bottom: 2 }} />
          <Grid container constrained margin={{ bottom: 3 }}>
            <Grid item xs={8}>
              <Typography variant="h4" gutterBottom>
                H4 / Red Hat Display Regular
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" gutterBottom>
                font-size: 35px (2.1875rem)
              </Typography>
              <Typography variant="body1" gutterBottom>
                line-height: 20px (1.25)
              </Typography>
              <Typography variant="body1" gutterBottom>
                letter-spacing: 0px
              </Typography>
              <Typography variant="body1" gutterBottom>
                text-align: center
              </Typography>
            </Grid>
          </Grid>
          <Divider margin={{ bottom: 2 }} />
          <Grid container constrained margin={{ bottom: 3 }}>
            <Grid item xs={8}>
              <Typography variant="h5" gutterBottom>
                H5 / Red Hat Display Regular
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" gutterBottom>
                font-size: 25px (1.5625rem)
              </Typography>
              <Typography variant="body1" gutterBottom>
                line-height: 16px (1)
              </Typography>
              <Typography variant="body1" gutterBottom>
                letter-spacing: 0px
              </Typography>
              <Typography variant="body1" gutterBottom>
                text-align: center
              </Typography>
            </Grid>
          </Grid>
          <Divider margin={{ bottom: 2 }} />
          <Grid container constrained margin={{ bottom: 3 }}>
            <Grid item xs={8}>
              <Typography variant="body1" gutterBottom align="center">
                Body / Red Hat Display Regular
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" gutterBottom>
                font-size: 16px (1rem)
              </Typography>
              <Typography variant="body1" gutterBottom>
                line-height: 20px (1.25)
              </Typography>
              <Typography variant="body1" gutterBottom>
                letter-spacing: 0px
              </Typography>
              <Typography variant="body1" gutterBottom>
                text-align: center
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid container justify="center">
        <Paper padding={3} margin={2} style={{ width: '90ch' }}>
          <Typography variant="h1" gutterBottom>
            Color Scheme
          </Typography>
          <Divider margin={{ bottom: 2 }} />
          <Grid container constrained margin={{ y: 3, left: 0 }} spacing={5}>
            <Grid item xs={9} className={classes.green}>
              <Typography variant="body1" gutterBottom></Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" gutterBottom>
                <strong>PRIMARY</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Nelnet Green
              </Typography>
              <Typography variant="body1" gutterBottom>
                #AED136
              </Typography>
            </Grid>
          </Grid>
          <Divider margin={{ bottom: 2 }} />
          <Grid container constrained margin={{ y: 3, left: 0 }} spacing={5}>
            <Grid item xs={9} className={classes.blue}>
              <Typography variant="body1" gutterBottom></Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" gutterBottom>
                <strong>SECONDARY</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Nelnet Dark
              </Typography>
              <Typography variant="body1" gutterBottom>
                #0E729A
              </Typography>
            </Grid>
          </Grid>
          <Divider margin={{ bottom: 2 }} />
          <Grid container constrained margin={{ y: 3, left: 0 }} spacing={5}>
            <Grid item xs={9} className={classes.lightBlue}>
              <Typography variant="body1" gutterBottom></Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" gutterBottom>
                <strong>SECONDARY LIGHT</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Nelnet Blue
              </Typography>
              <Typography variant="body1" gutterBottom>
                #11AECF
              </Typography>
            </Grid>
          </Grid>
          <Divider margin={{ bottom: 2 }} />
          <Grid container constrained margin={{ y: 3, left: 0 }} spacing={5}>
            <Grid item xs={9} className={classes.magenta}>
              <Typography variant="body1" gutterBottom></Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" gutterBottom>
                <strong>EXTENDED</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Nelnet Magenta
              </Typography>
              <Typography variant="body1" gutterBottom>
                #CD2F79
              </Typography>
            </Grid>
          </Grid>
          <Divider margin={{ bottom: 2 }} />
          <Grid container constrained margin={{ y: 3, left: 0 }} spacing={5}>
            <Grid item xs={9} className={classes.purple}>
              <Typography variant="body1" gutterBottom></Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" gutterBottom>
                <strong>EXTENDED</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Nelnet Purple
              </Typography>
              <Typography variant="body1" gutterBottom>
                #6867AF
              </Typography>
            </Grid>
          </Grid>
          <Divider margin={{ bottom: 2 }} />
          <Grid container constrained margin={{ y: 3, left: 0 }} spacing={5}>
            <Grid item xs={9} className={classes.darkGray}>
              <Typography variant="body1" gutterBottom></Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" gutterBottom>
                <strong>EXTENDED</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Nelnet Dark Gray
              </Typography>
              <Typography variant="body1" gutterBottom>
                #565B64
              </Typography>
            </Grid>
          </Grid>
          <Divider margin={{ bottom: 2 }} />
          <Grid container constrained margin={{ y: 3, left: 0 }} spacing={5}>
            <Grid item xs={9} className={classes.lightGray}>
              <Typography variant="body1" gutterBottom></Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" gutterBottom>
                <strong>EXTENDED</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Nelnet Light Gray
              </Typography>
              <Typography variant="body1" gutterBottom>
                #EDEEEF
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid container justify="center">
        <Paper padding={3} margin={2} style={{ width: '90ch' }}>
          <Typography variant="h1" gutterBottom>
            Navigation
          </Typography>
          <Divider margin={{ bottom: 2 }} />
          <Grid container constrained margin={{ bottom: 3 }}>
            <Grid item xs={3}>
              <Typography align="center">
                <Button>Button</Button>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography align="center">
                <Button margin={{ bottom: 2 }}>Button</Button>
              </Typography>
              <Typography variant="body1" align="center" gutterBottom>
                Hover
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography align="center">
                <Button margin={{ bottom: 2 }}>Button</Button>
              </Typography>
              <Typography variant="body1" align="center" gutterBottom>
                Focus
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" gutterBottom>
                Button / Contained
              </Typography>
            </Grid>
          </Grid>
          <Divider margin={{ bottom: 2 }} />
          <Grid container constrained margin={{ bottom: 3 }}>
            <Grid item xs={9} sm={3}>
              <Typography align="center">
                <Button variant="outlined" margin={{ bottom: 4 }}>
                  Button Outline
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={9} sm={3}>
              <Typography align="center">
                <Button variant="outlined" margin={{ bottom: 2 }}>
                  Button Outline
                </Button>
              </Typography>
              <Typography variant="body1" gutterBottom align="center">
                Hover
              </Typography>
            </Grid>
            <Grid item xs={9} sm={3}>
              <Typography align="center">
                <Button variant="outlined" margin={{ bottom: 2 }}>
                  Button Outline
                </Button>
              </Typography>
              <Typography variant="body1" gutterBottom align="center">
                Focus
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" gutterBottom>
                Button / Outline
              </Typography>
            </Grid>
          </Grid>
          <Divider margin={{ bottom: 2 }} />
          <Grid container constrained margin={{ bottom: 3 }}>
            <Grid item xs={3}>
              <Typography align="center">
                <Button variant="text">Button Text</Button>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography align="center">
                <Button variant="text" margin={{ bottom: 3 }}>
                  Button Text
                </Button>
              </Typography>
              <Typography variant="body1" align="center">
                Hover
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography align="center">
                <Button variant="text" margin={{ bottom: 3 }}>
                  Button Text
                </Button>
              </Typography>
              <Typography variant="body1" align="center">
                Focus
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1">Button / Text</Typography>
            </Grid>
          </Grid>
          <Divider margin={{ bottom: 2 }} />
          <Grid container constrained margin={{ bottom: 3 }}>
            <Grid item xs={3}>
              <Typography align="center" gutterBottom>
                <Link href="#" underline="always" color="secondary">
                  Text Link
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography align="center" gutterBottom>
                <Link href="#" underline="always" color="secondary">
                  Text Link
                </Link>
              </Typography>
              <Typography variant="body1" align="center">
                Hover
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography align="center" gutterBottom>
                <Link href="#" underline="always" color="secondary">
                  Text Link
                </Link>
              </Typography>
              <Typography variant="body1" align="center">
                Focus
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1">Text Link</Typography>
            </Grid>
          </Grid>
          <Divider margin={{ bottom: 2 }} />
          <Grid container constrained margin={{ bottom: 3 }}>
            <Grid item xs={3}>
              <Typography className={classes.darkGray} align="center">
                <Link href="#" underline="always" color="textOnDark">
                  Text Link
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.darkGray} align="center">
                <Link href="#" underline="always" color="textOnDark">
                  Text Link
                </Link>
              </Typography>
              <Typography variant="body1" gutterBottom align="center">
                Hover
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Paper padding={{ right: 3 }} elevation={0}>
                <Typography className={classes.darkGray} align="center">
                  <Link href="#" underline="always" color="textOnDark">
                    Text Link
                  </Link>
                </Typography>
                <Typography variant="body1" gutterBottom align="center">
                  Focus
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" gutterBottom>
                Text Link Reversed
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid container justify="center">
        <Paper padding={3} margin={2} style={{ width: '90ch' }}>
          <Typography variant="h1" gutterBottom>
            Messages
          </Typography>
          <Divider margin={{ bottom: 2 }} />
          <Grid container constrained spacing={5}>
            <Grid item xs={9}>
              <Notification type="info" icon={<Info />}>
                <Typography>
                  <strong>Info Heading</strong>
                </Typography>
                <Typography>Body</Typography>
              </Notification>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" gutterBottom>
                Information
              </Typography>
            </Grid>
          </Grid>
          <Grid container constrained spacing={5}>
            <Grid item xs={9}>
              <Notification type="error" icon={<ReportProblem />}>
                <Typography>
                  <strong>Alert Heading</strong>
                </Typography>
                <Typography>Body</Typography>
              </Notification>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" gutterBottom>
                Alert
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(KitchenSink);
